import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

// layouts
import AddUser from '../views/admin/users/AddUser';
import AddBussinessUser from 'src/views/admin/users/AddBussinessUser';
import EditUser from 'src/views/admin/users/EditUser';
import Coaches from 'src/views/admin/coaches';
import DashLayout from '../layouts/Dash';
import AuthLayout from '../layouts/Auth';

// views without layouts
import Login from '../views/auth/Login';
import Users from '../views/admin/users';
import CoachDetail from 'src/layouts/CoachDetail';
import AddCoach from 'src/views/admin/coaches/AddCoach';
import Otp from 'src/views/auth/Otp';
import ResetPassword from 'src/views/auth/ResetPassword';
import AddUserProfilePicture from 'src/views/admin/users/AddUserProfilePicture';
import AddUserPhotos from 'src/views/admin/users/AddUserPhotos';
import EditUserProfilePicture from 'src/views/admin/users/EditUserProfilePicture';
import EditUserPhotos from 'src/views/admin/users/EditUserPhotos';
import AddCoachAdditionalInfo from 'src/views/admin/coaches/AddCoachAdditionalInfo';
import EditCoach from 'src/views/admin/coaches/EditCoach';
import EditCoachAdditionalInfo from 'src/views/admin/coaches/EditCoachAdditionalInfo';
import UserDetail from 'src/views/admin/users/UserDetail';
import ReviewUser from 'src/views/admin/users/ReviewUser';
import NotFound from 'src/views/NotFound';
import OutletRole from './OutletRole';
import { useAppSelector } from 'src/hooks';
import Appointments from 'src/views/wellness/Appointments';
import Stories from 'src/components/cards/admin/stories/StoriesCard';
import AddLocalizedStory from 'src/views/admin/stories/AddLocalizedStory';
import { EditStory } from 'src/views/admin/stories/EditStory';
import AddNewLocalizedStoryLanguage from 'src/views/admin/stories/AddNewLocalizedStoryLanguage';
import ForgotPassword from 'src/views/auth/ForgotPassword';
import ProtectedRoute from './ProtectedRoute';
import CurrentCoachDetail from 'src/layouts/CurrentCoachDetail';
import EditCurrentCoach from 'src/views/wellness/EditCurrentCoach';
import EditCurrentCoachAdditionalInfo from 'src/views/wellness/EditCurrentCoachAdditionalInfo';
import Loading from 'src/ui/loading/Loading';
import Settings from 'src/views/admin/settings';
import ResponsePopup from 'src/ui/popups/ResponsePopup';
import StoryDetail from 'src/views/admin/stories/StoryDetail';
import EditBussinessUser from 'src/views/admin/users/EditBussinessUser';

const Router = () => {
  const role = useAppSelector((state) => state.auth.role);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/auth' element={<AuthLayout />}>
          <Route path='/auth' element={<Navigate to='/auth/login' replace />} />
          <Route path='login' element={<Login />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='code-validation' element={<Otp />} />
          <Route path='change-password' element={<ResetPassword />} />
        </Route>
        {/* add routes with layouts */}
        <Route element={<ProtectedRoute />}>
          {role === 'admin' && (
            <>
              <Route path='/admin' element={<OutletRole />}>
                <Route path='users' element={<DashLayout />}>
                  <Route index element={<Users />} />
                  <Route path=':userId/user-detail' element={<UserDetail review={false} />} />
                  <Route path='add-user' element={<AddUser />} />
                  <Route path='add-bussiness-user' element={<AddBussinessUser />} />
                  <Route path='add-user/profile-picture' element={<AddUserProfilePicture />} />
                  <Route path='add-user/photos' element={<AddUserPhotos />} />
                  <Route path=':userId/edit-user' element={<EditUser />} />
                  <Route path=':userId/edit-bussiness-user' element={<EditBussinessUser />} />
                  <Route
                    path=':userId/edit-user/profile-picture'
                    element={<EditUserProfilePicture />}
                  />
                  <Route path=':userId/edit-user/photos' element={<EditUserPhotos />} />
                  <Route path=':userId/review-profile' element={<ReviewUser />} />
                </Route>
                <Route path='coaches' element={<DashLayout />}>
                  <Route index element={<Coaches />} />
                  <Route path=':coachId/coach-detail' element={<CoachDetail />} />
                  <Route path='add-coach' element={<AddCoach />} />
                  <Route path='add-coach/additional-info' element={<AddCoachAdditionalInfo />} />
                  <Route path=':coachId/edit-coach' element={<EditCoach />} />
                  <Route
                    path=':coachId/edit-coach/additional-info'
                    element={<EditCoachAdditionalInfo />}
                  />
                </Route>
                <Route path='stories' element={<DashLayout />}>
                  <Route index element={<Stories />} />
                  <Route path='add-localized-story' element={<AddLocalizedStory />} />
                  <Route
                    path=':storyId/add-localized-story'
                    element={<AddNewLocalizedStoryLanguage />}
                  />
                  <Route path=':storyId/edit-story' element={<EditStory />} />
                  <Route path=':storyId/story-detail/:activeLang' element={<StoryDetail />} />
                </Route>
                <Route path='friends' element={<DashLayout />}>
                  <Route index element={<Users />} />
                </Route>
                <Route path='events' element={<DashLayout />}>
                  <Route index element={<Users />} />
                </Route>
                <Route path='analytics' element={<DashLayout />}>
                  <Route index element={<Users />} />
                </Route>
                <Route path='refunds' element={<DashLayout />}>
                  <Route index element={<Users />} />
                </Route>
                <Route path='settings' element={<DashLayout />}>
                  <Route index element={<Settings />} />
                </Route>
              </Route>
            </>
          )}
          {role === 'coach' && (
            <Route path='/coach' element={<DashLayout />}>
              <Route path='appointments' element={<Appointments />} />
              <Route path='profile' element={<CurrentCoachDetail />}></Route>
              <Route path='edit-profile' element={<OutletRole />}>
                <Route index element={<EditCurrentCoach />} />
                <Route path='additional-info' element={<EditCurrentCoachAdditionalInfo />} />
              </Route>
              <Route path='settings' element={<Settings />}></Route>
            </Route>
          )}
        </Route>
        {/* add routes without layouts */}
        <Route path='/' element={<Navigate to='/auth/login' replace />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Loading />
      <ResponsePopup />
    </BrowserRouter>
  );
};

export default Router;
