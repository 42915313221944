import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useEffect } from 'react';

// Interfaces
import { UserGeneralInfo, UsersDetailAllInfo } from 'src/interfaces/usersInterface';

// Components
import UserForm from '../../../components/forms/admin/users/UserForm';

// Services
import { createUserUseCase } from 'src/services/users/useCases';

const AddUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const addUserView = useAppSelector((state) => state.users.addUserView);
  const handleSuccess = async (values: UsersDetailAllInfo) => {
    const postUserGeneralInfo = Object.assign({}, values);

    if (!values.location?.cityId && !values.location?.stateId) {
      delete postUserGeneralInfo?.location;
    }
    if (!values.description) {
      delete postUserGeneralInfo?.description;
    }
    if (!values.phone) {
      delete postUserGeneralInfo?.phone;
    }
    //if (!values.gender) {
    //delete postUserGeneralInfo?.gender;
    //}
    if (!values.birthDay) {
      delete postUserGeneralInfo?.birthDay;
    }
    delete postUserGeneralInfo?.isEditing;
    delete postUserGeneralInfo.photos;
    delete postUserGeneralInfo.profilePicture;
    delete postUserGeneralInfo.lastName;

    dispatch(createUserUseCase(postUserGeneralInfo as UserGeneralInfo));
  };

  useEffect(() => {
    if (addUserView === 'profile-picture') {
      navigate('profile-picture');
    }
  }, [addUserView, navigate]);

  return <UserForm onSuccess={handleSuccess} isEditing={false} />;
};

export default AddUser;
