import { FC, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

interface AddUserOptionProps {
  header: string;
  privateUser: string;
  businessUser: string;
}

const AddUserOption: FC<AddUserOptionProps> = ({ header, privateUser, businessUser }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLAnchorElement>(null);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        event.target !== toggleButtonRef.current
      ) {
        handleDropdownClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`flex justify-between relative w-full px-4 max-w-full flex-grow flex-1 ${
        isDropdownOpen ? 'dropdown-open' : ''
      }`}
      ref={dropdownRef}
    >
      <span className='flex'>
        <div className={`him-users mr-2 text-lg`}>{header}</div>
        <Link
          onClick={() => handleDropdownToggle()}
          to=''
          className='linkBtn'
          ref={toggleButtonRef}
        >
          +
        </Link>
      </span>
      {isDropdownOpen && (
        <div className='dropdown-container' style={{ border: '1px solid #A06AB4' }}>
          <Link to='add-user' className='dropdown-item'>
            {privateUser}
          </Link>
          <Link to='add-bussiness-user' className='dropdown-item'>
            {businessUser}
          </Link>
        </div>
      )}
    </div>
  );
};

export default AddUserOption;
