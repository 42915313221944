import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import getLocation from '../../../../assets/countries.json';
import moment from 'moment';
import Input from '../../../../ui/Input';
import Button from '../../../../ui/Button';
import Icon from '../../../../ui/Icon';

// Interfaces
import { userFormFormikProps, UserFormProps } from '../../../../interfaces/ui/formInterface';
import { Location } from 'src/interfaces/generalInterface';
import Phone from 'src/ui/Phone';

const UserForm = (props: UserFormProps) => {
  const { onSuccess, data, isEditing } = props;
  const { t } = useTranslation(['addEditUserForm']);
  const location = getLocation.states;
  let stateEditing: any = '';
  const navigate = useNavigate();
  const genders = [
    { name: t('genderTwo'), id: 1, value: t('genderTwo') },
    { name: t('genderOne'), id: 2, value: t('genderOne') },
    { name: t('genderThree'), id: 3, value: t('genderThreeUsable') },
  ];

  const findState2 = (stateId: number | undefined | string) => {
    const state = location.find((state: Location) => state.id === stateId);
    stateEditing = state;
    return state?.id;
  };

  const findCity = (cityId: number | undefined | string) => {
    if (stateEditing) {
      const city = stateEditing.cities.find((city: any) => city.id === cityId);
      return city?.id;
    } else return '';
  };

  const formik = useFormik<userFormFormikProps>({
    initialValues: {
      fullName: data?.fullName || '',
      email: data?.email || '',
      password: '',
      phone: data?.phone || '',
      gender: data?.gender || 0,
      location: {
        stateId: data?.stateId !== 0 ? findState2(data?.stateId) : '',
        cityId: data?.cityId !== 0 ? findCity(data?.cityId) : '',
      },
      birthDay: data?.birthDay ? moment(data?.birthDay).toDate() : null,
      description: data?.description || '',
      isEditing: isEditing,
      preferredLocale: 'en-US',
      userType: 'PrivatePay',
      // businessName: 'null',
      // spocName: 'null',
      // spocEmail: 'null',
      buccPopup: false,
      // startDate: '',
      // endDate: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      fullName: Yup.string()
        .required(t('errorRequiredOne'))
        .min(3, t('errorCharactersOne'))
        .trim()
        .matches(/^[A-Za-z]*(\s[A-Za-z]*)+$/, t('errorCharactersTwo')),
      email: Yup.string().when('isEditing', {
        is: false,
        then: Yup.string().email(t('errorValidTwo')).required(t('errorRequiredTwo')),
        otherwise: Yup.string(),
      }),
      password: Yup.string().when('isEditing', {
        is: false,
        then: Yup.string()
          .required(t('errorRequiredThree'))
          .min(8, t('errorPasswordOne'))
          .matches(/[a-z]+/, t('errorPasswordTwo'))
          .matches(/[A-Z]+/, t('errorPasswordThree'))
          .matches(/\d+/, t('errorPasswordFour')),
      }),
      phone: Yup.number(),
      gender: Yup.string(),
      location: Yup.object().shape(
        {
          stateId: Yup.string().when('cityId', {
            is: (cityId: string) => cityId && cityId.length > 0,
            then: Yup.string().required(t('errorStateReq')),
            otherwise: Yup.string().nullable(),
          }),
          cityId: Yup.string().when('stateId', {
            is: (stateId: string) => stateId && stateId.length > 0,
            then: Yup.string().required(t('errorCityReq')),
            otherwise: Yup.string().nullable(),
          }),
        },
        [['cityId', 'stateId']],
      ),
      birthDay: Yup.date().nullable(),
      description: Yup.string().max(200, t('errorOptionalTwo')),
    }),
    onSubmit: (values) => {
      onSuccess(values);
    },
  });

  const subtractYears = (numOfYears: number, date = new Date()) => {
    date.setFullYear(date.getFullYear() - numOfYears);
    return date;
  };

  const findState: any = () => {
    if (formik.values.location.stateId) {
      const state = location.find(
        (state: any) => state.id === Number(formik.values.location.stateId),
      );
      return state;
    }
  };

  return (
    <div className='relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-2xl bg-white text-primary overflow-auto'>
      <div className='flex items-center p-3 bg-background'>
        <Icon icon='backBtn' onClick={() => navigate(-1)} />
        <span className='flex font-semibold text-sm mx-2 uppercase'>
          {data ? t('headerEdit') : t('headerAdd')}
        </span>
      </div>
      <div className='formContainer' style={{ maxHeight: '75vh' }}>
        <form onSubmit={formik.handleSubmit} className='bg-white shadow-md rounded px-8 pt-6 pb-4'>
          <div className='formUsersContainer'>
            <div className='mr-8 flex-1'>
              <div className='formUsersHeader'>{t('requiredOne')}</div>
              {formik.touched.fullName && formik.errors.fullName && (
                <div className='formUsersError'>{formik.errors.fullName}</div>
              )}
              <Input
                extraClass='w-full'
                type='text'
                name='fullName'
                value={formik.values.fullName}
                placeholder='John'
                onBlur={formik.handleBlur}
                onChange={(e) => formik.handleChange(e)}
                haveError={formik.touched.fullName && formik.errors.fullName}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                  !/^[A-Za-z\s]*$/.test(e.key) && e.preventDefault()
                }
              />
            </div>
            {!isEditing && (
              <div className='mr-8 flex-1'>
                <div className='formUsersHeader'>{t('requiredTwo')}</div>
                {formik.touched.email && formik.errors.email && (
                  <div className='formUsersError'>{formik.errors.email}</div>
                )}
                <Input
                  extraClass='w-full'
                  type='text'
                  name='email'
                  value={formik.values.email}
                  placeholder='john.doe@example.com'
                  onBlur={formik.handleBlur}
                  onChange={(e) => formik.handleChange(e)}
                  haveError={formik.touched.email && formik.errors.email}
                />
              </div>
            )}
            {!data && (
              <div className='flex-1'>
                <div className='formUsersHeader'>{t('requiredThree')}</div>
                {formik.touched.password && formik.errors.password && (
                  <div className='formUsersError'>{formik.errors.password}</div>
                )}
                <Input
                  extraClass='w-full'
                  type='text'
                  name='password'
                  value={formik.values.password}
                  placeholder={t('requiredThree')}
                  onBlur={formik.handleBlur}
                  onChange={(e) => formik.handleChange(e)}
                  haveError={formik.touched.password && formik.errors.password}
                />
              </div>
            )}
          </div>
          <div className='mt-8'>
            <hr />
          </div>
          <div className='font-medium text-lg pb-4 mt-8 capitalize'>{t('headerOptional')}</div>
          <div className='formUsersContainer'>
            <div className='flex flex-col mr-8'>
              <div className='formUsersHeader font-medium'>{t('optionalLocation')}</div>
              <div className='flex-1 relative'>
                <div className='formUsersHeader'>{t('optionalEight')}</div>
                {formik.touched.location?.stateId && formik.errors.location?.stateId && (
                  <div className='formUsersError'>{formik.errors.location?.stateId}</div>
                )}
                <select
                  className='w-full formUsersInput'
                  name='location.stateId'
                  onChange={(e) => formik.handleChange(e)}
                  value={formik.values.location?.stateId as string}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>{t('selectThree')}</option>
                  {location.map((state: any) => (
                    <option value={state.id} key={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='flex-1'>
                <div className='formUsersHeader'>{t('optionalNine')}</div>
                {formik.touched.location?.cityId && formik.errors.location?.cityId && (
                  <div className='formUsersError'>{formik.errors.location?.cityId}</div>
                )}
                <select
                  className='w-full formUsersInput'
                  name='location.cityId'
                  onChange={(e) => formik.handleChange(e)}
                  value={formik.values.location?.cityId as string}
                  onBlur={formik.handleBlur}
                >
                  <option value=''>{t('selectFour')}</option>
                  {findState()?.cities.map((city: any) => (
                    <option value={city.id} key={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='flex grow flex-col'>
              <div>
                <div className='formUsersHeader'>{t('optionalTwo')}</div>
                {formik.touched.description && formik.errors.description && (
                  <div className='formUsersError'>{formik.errors.description}</div>
                )}
                <textarea
                  name='description'
                  onBlur={formik.handleBlur}
                  onChange={(e) => formik.handleChange(e)}
                  value={formik.values.description ? formik.values.description : ''}
                  placeholder={t('optionalTwoPlaceholder')}
                  className='h-40 formUsersInput rounded w-full ease-linear'
                />
              </div>
            </div>
          </div>
          <div className='formUsersContainer mt-8'>
            <div className='mr-8 flex-1'>
              <div className='formUsersHeader'>{t('optionalFour')}</div>
              {formik.touched.phone && formik.errors.phone && (
                <div className='formUsersError'>{formik.errors.phone}</div>
              )}
              <Phone formik={formik} />
            </div>
            <div className='mr-8 flex-1'>
              <div className='formUsersHeader'>{t('optionalFive')}</div>
              {formik.touched.gender && formik.errors.gender && (
                <div className='formUsersError'>{formik.errors.gender}</div>
              )}
              <select
                className='w-full formUsersInput'
                name='gender'
                onChange={(e) => formik.handleChange(e)}
                value={formik.values.gender}
                onBlur={formik.handleBlur}
              >
                <option value=''>{t('selectOne')}</option>
                {genders.map((gender) => (
                  <option value={gender.value} key={gender.id}>
                    {gender.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='flex-1'>
              <div className='text-primary text-sm flex items-center'>
                <span className='formUsersHeader mb-0'>{t('optionalSix')}</span>
                <ReactTooltip
                  id='info-icon'
                  place='top'
                  className='w-56 bg-secondary text-primary rounded-full !important'
                  backgroundColor='bg-secondary'
                  arrowColor='#9362C7'
                  textColor='white'
                />
                <i data-for='info-icon' data-tip={t('over18')}>
                  <Icon icon='info' />
                </i>
              </div>
              {formik.touched.birthDay && formik.errors.birthDay && (
                <div className='formUsersError'>{formik.errors.birthDay}</div>
              )}
              <DatePicker
                placeholderText='MM/DD/YYYY'
                selected={formik.values.birthDay}
                name='birthDay'
                onChange={(date: Date | null) => formik.setFieldValue('birthDay', date)}
                className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded-full text-sm shadow focus:ring-0 focus:outline-2 focus:border focus:border-secondary w-full ease-linear transition-all duration-150
              ${formik.touched.birthDay && formik.errors.birthDay ? 'border-alert' : ''}`}
                dateFormat='MM/dd/yyyy'
                maxDate={subtractYears(18)}
                dateFormatCalendar='MMMM'
                showYearDropdown
                isClearable
                scrollableYearDropdown
                yearDropdownItemNumber={50}
              />
            </div>
          </div>
          <div className='flex justify-center'>
            <Button type='submit' extraClass='w-2/12 p-2 mt-4'>
              {`${data ? t('nextBtn') : t('submitBtn')}`}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForm;
