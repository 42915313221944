// Interfaces
import { IFile } from 'src/interfaces/generalInterface';
import { UserGeneralInfo } from 'src/interfaces/usersInterface';

// Redux
import { setIsLoading, setResponsePopup } from 'src/redux/slices/generalSlice';
import {
  createUser,
  deactivateActivateUser,
  deleteUser,
  deleteUserProfilePicture,
  setAddUserView,
  setEditUserView,
  setMetadata,
  setRejectProfileOptions,
  setUserDetail,
  setUsers,
  setBussinessUser,
  updateUser,
  setBuSubscriptionList,
  setBussinessName,
} from 'src/redux/slices/users.slice';
import { AppThunk } from 'src/redux/store';

// Services
import {
  createUserAction,
  deleteUserAction,
  deleteUserProfilePictureAction,
  getRejectReasonsAction,
  getUserDetailAction,
  getUsersListAction,
  handleApproveRejectUserProfileAction,
  updateUserAction,
  updateUserStatusAction,
  uploadProfilePhotosAction,
  uploadProfilePictureAction,
  getBuSubscriptionListListAction,
  getBussinessUserListAction,
  getSpocListAction,
} from './requests';

// Delete user use case
export const deleteUserUseCase =
  (publicId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const resp = await deleteUserAction(publicId);
      if (resp.status !== 200) {
        throw new Error('Something went wrong');
      }
      dispatch(setIsLoading(false));
      dispatch(deleteUser(publicId));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'success',
          header: 'Done!',
          subtitle: 'The user has been successfully deleted.',
          submitBtn: 'Ok',
        }),
      );
    } catch (error: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle: error.response.data.detail,
          submitBtn: 'Ok',
        }),
      );
    }
  };

// Delete user profile picture use case
export const deleteUserProfilePictureUseCase =
  (publicId: string | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await deleteUserProfilePictureAction(publicId)
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status === 200) {
          dispatch(deleteUserProfilePicture(publicId));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'The profile picture has been deleted.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Deactivate / activate user use case
export const updateUserStatusUseCase =
  (props: { publicId: string | undefined; status: boolean }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await updateUserStatusAction({ publicId: props.publicId, status: props.status })
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.data.message === 'User successfully deactivated.') {
          dispatch(deactivateActivateUser({ userId: props.publicId, status: 'Deactivated' }));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'The user has been successfully deactivated.',
              submitBtn: 'Ok',
            }),
          );
        } else if (res.data.message === 'User successfully activated.') {
          dispatch(deactivateActivateUser({ userId: props.publicId, status: 'Registered' }));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'The user has been successfully activated.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Create user use case
export const createUserUseCase =
  (newUser: UserGeneralInfo): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await createUserAction(newUser)
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status == 200) {
          dispatch(createUser(res.data));
          const idResponse = res.data.data.publicId;
          localStorage.setItem('current-user', JSON.stringify(idResponse));
          dispatch(setAddUserView('profile-picture'));
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        } else if (err.response.status === 400) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: 'Unable to create a user with an existing coach email.',
              submitBtn: 'Ok',
            }),
          );
        } else {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Upload user profile picture use case
export const uploadProfilePictureUseCase =
  (props: { publicId: string | undefined; profilePicture: FormData }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await uploadProfilePictureAction({
      profilePicture: props.profilePicture,
      publicId: props.publicId,
    })
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setAddUserView('photos'));
        dispatch(setEditUserView('photos'));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Upload user photos use case
export const uploadProfilePhotosUseCase =
  (props: { publicId: string; profilePhotos: FormData }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await uploadProfilePhotosAction({
      profilePhotos: props.profilePhotos,
      publicId: props.publicId,
    })
      .then(() => {
        dispatch(setIsLoading(false));
        dispatch(setAddUserView('finish'));
        dispatch(setEditUserView('finish'));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Update user use case
export const updateUserUseCase =
  (props: { publicId: string | undefined; updatedUser: any }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await updateUserAction(props)
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status === 200) {
          dispatch(updateUser(props.updatedUser));
          dispatch(setEditUserView('profile-picture'));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'The user has been edited.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Get user detail use case
export const getUserDetailUseCase =
  (publicId: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getUserDetailAction(publicId)
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(setUserDetail(res.data.data));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Upload photos first endpoint
export const uploadAllUserPhotosAction =
  (photos: IFile[]): AppThunk =>
  async (dispatch) => {
    const currentUserId = JSON.parse(localStorage.getItem('current-user') || '');
    const send = new FormData();
    photos.forEach((photo) => {
      if (!photo.inServer) send.append('profilePhotos', photo.file);
    });
    const count = Array.from(
      send.entries(),
      (
        [key, prop], // validate if send images length
      ) => ({
        [key]: {
          ContentLength: typeof prop === 'string' ? prop.length : prop.size,
        },
      }),
    );
    if (count.length > 0) {
      dispatch(uploadProfilePhotosUseCase({ publicId: currentUserId, profilePhotos: send }));
    } else {
      return true;
    }
  };

// handle approve/reject user profile use case
export const handleApproveRejectUserProfileUseCase =
  (props: {
    publicId?: string;
    data: { isAValidProfile: boolean; reasonsList: any[] };
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await handleApproveRejectUserProfileAction(props)
      .then((res) => {
        dispatch(setIsLoading(false));
        if (res.status === 200) {
          dispatch(setEditUserView('finish'));
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'success',
              header: 'Done!',
              subtitle: 'Changes have been successfully saved.',
              submitBtn: 'Ok',
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Get users list use case
export const getUsersListUseCase =
  (users?: { page?: number; limit?: number }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    const data = await getUsersListAction(users)
      .then((res) => {
        dispatch(setIsLoading(false));
        dispatch(setUsers(res.data.data));
        dispatch(setMetadata(res.data.metadata));
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
        if (err.response.data.status === 409) {
          dispatch(
            setResponsePopup({
              isVisible: true,
              type: 'error',
              header: 'Error',
              subtitle: err.response.data.detail,
              submitBtn: 'Ok',
            }),
          );
        }
      });
    return data;
  };

// Get Business users list use case
export const getSpocUserListUseCase =
  (SearchKey: string): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const res = await getSpocListAction(SearchKey);
      dispatch(setIsLoading(false));
      dispatch(setBussinessUser(res.data.data));
      dispatch(setMetadata(res.data.metadata));
    } catch (err: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle:
            err.response?.data?.detail || 'An error occurred while fetching business users.',
          submitBtn: 'Ok',
        }),
      );
    }
  };

// Get Business users list use case
export const getBussinessNameListUseCase = (): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const res = await getBussinessUserListAction();
    dispatch(setIsLoading(false));
    dispatch(setBussinessName(res.data.data));
    dispatch(setMetadata(res.data.metadata));
  } catch (err: any) {
    dispatch(setIsLoading(false));
    dispatch(
      setResponsePopup({
        isVisible: true,
        type: 'error',
        header: 'Error',
        subtitle: err.response?.data?.detail || 'An error occurred while fetching business users.',
        submitBtn: 'Ok',
      }),
    );
  }
};

// Get users list use case
export const getBUSubscriptonListUseCase =
  (buSubscribeList?: { page?: number; limit?: number }): AppThunk =>
  async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
      const res = await getBuSubscriptionListListAction(buSubscribeList);
      dispatch(setIsLoading(false));
      dispatch(setBuSubscriptionList(res.data.data));
      dispatch(setMetadata(res.data.metadata));
    } catch (err: any) {
      dispatch(setIsLoading(false));
      dispatch(
        setResponsePopup({
          isVisible: true,
          type: 'error',
          header: 'Error',
          subtitle:
            err.response?.data?.detail || 'An error occurred while fetching business users.',
          submitBtn: 'Ok',
        }),
      );
    }
  };

// Get reject reasons use case
export const getRejectReasonsUseCase = (): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  const data = await getRejectReasonsAction()
    .then((res) => {
      dispatch(setIsLoading(false));
      dispatch(setRejectProfileOptions(res.data));
    })
    .catch((err) => {
      dispatch(setIsLoading(false));
      if (err.response.data.status === 409) {
        dispatch(
          setResponsePopup({
            isVisible: true,
            type: 'error',
            header: 'Error',
            subtitle: err.response.data.detail,
            submitBtn: 'Ok',
          }),
        );
      }
    });
  return data;
};
