import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import usersImage from '../../assets/img/users.svg';
import coachesImage from '../../assets/img/coaches.svg';
import storiesImg from '../../assets/img/stories.svg';
import appImg from '../../assets/img/events.svg';
import settingsImg from '../../assets/img/settings.svg';
import logo from '../../assets/img/circle-logo.png';
import { useAppSelector } from 'src/hooks';

// Components
import UserDropdown from '../dropdowns/UserDropdown';
import Sidelink from './Sidelink';

const Sidebar = () => {
  const { t } = useTranslation(['sidebar']);
  const [collapseShow, setCollapseShow] = useState('hidden');
  const role = useAppSelector((state) => state.auth.role);

  return (
    <nav className='side-container'>
      <div className='md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
        {/* Toggler */}
        <button
          className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
          type='button'
          onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
        >
          <i className='fas fa-bars' />
        </button>
        {/* Brand */}
        <Link
          className='flex w-full text-left md:pb-2 text-blueGray-600 mr-0 whitespace-nowrap text-sm capitalize font-bold p-4 px-0 items-center'
          to='/'
        >
          <span className='w-16 mr-4 ml-4'>
            <img src={logo} alt='logo' />
          </span>
          {/* <img src={logo} alt='logo' /> */}
          {t('header')}
        </Link>
        {/* User */}
        <ul className='md:hidden items-center flex flex-wrap list-none'>
          <li className='inline-block relative'>
            <UserDropdown />
          </li>
        </ul>
        {/* Collapse */}
        <div
          className={`md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ${collapseShow}`}
        >
          {/* Collapse header */}
          <div className='md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200'>
            <div className='flex flex-wrap'>
              <div className='w-6/12'>Hello it`s me!</div>
              <div className='w-6/12 flex justify-end'>
                <button
                  type='button'
                  className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                  onClick={() => setCollapseShow('hidden')}
                >
                  <i className='fas fa-times' />
                </button>
              </div>
            </div>
          </div>
          <hr className='my-4 md:min-w-full' />
          {/* Navigation */}
          <ul className='md:flex-col md:min-w-full flex flex-col list-none'>
            {role === 'admin' && (
              <>
                <Sidelink
                  text={t('firstNavLink')}
                  imgSrc={usersImage}
                  imgClass='w-4 mr-2'
                  section='users'
                />
                <Sidelink text={t('secondNavLink')} imgSrc={coachesImage} section='coaches' />
                <Sidelink text={t('thirdNavLink')} imgSrc={storiesImg} section='stories' />
                <Sidelink text={t('eightNavLink')} imgSrc={settingsImg} section='settings' />
              </>
            )}
            {role === 'coach' && (
              <>
                <Sidelink text={t('tenNavLink')} imgSrc={appImg} section='appointments' />
                <Sidelink text={t('nineNavLink')} imgSrc={usersImage} section='profile' />
                <Sidelink text={t('eightNavLink')} imgSrc={settingsImg} section='settings' />
              </>
            )}
          </ul>
          <hr className='my-4 md:min-w-full' />
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
