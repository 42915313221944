const USERS_LIST = 'users/list';
const BUSSINESS_LIST = 'users/getspocdetails-list';
const BUSSINESS_USER_LIST = 'users/getbusinessunitnames-list';
const USER = 'users';
const ADD_PROFILE_PICTURE = 'users/:param/profile-picture';
const ADD_PHOTOS = 'users/:param/photos';
const DELETE_USER = 'users/';
const UPDATE_USER = 'users?publicId=:param';
const DELETE_PROFILE_PICTURE = 'users/:param/profile-picture';
const COACHES_LIST = 'coaches/list';
const COACH = 'coaches';
const DELETE_PHOTO = 'users/:param/photos/:secondParam';
const UPDATE_USER_STATUS = 'users/:param/activate-user';
const UPDATE_COACH_STATUS = 'coaches/:param/activate-coach';
const ADD_COACH_PICTURE = '/coaches/:param/profile-picture';
const ADD_CERTIFICATES = '/coaches/:param/certificates';
const UPDATE_COACH = 'coaches?coachId=:param';
const DELETE_COACH_PROFILE_PICTURE = 'coaches/:param/profile-picture';
const DELETE_CERTIFICATION = 'coaches/:param/certificates?certificationId=:secondParam';
const REJECTION_REASONS = 'approve-profile/reasons';
const APPROVE_PROFILE = 'users/:param/approve-profile';
// const COACH_CALENDAR = 'coaches/:param/calendar';
const COACH_AVAILABILITY = 'coaches/:param/availability';
const DELETE_AVAILABILITY = 'coaches/:param/availability';
const WELLNESS_SESSIONS = 'coaches/:param/appointments';
const STORIES = 'stories';
const STORY_MEDIA = 'stories/media';
const LOCALIZED_STORY = 'stories/:param/localized-stories';
const PATCH_LOCALIZED_STORY = 'stories/localized-stories/:param';
const STORY = 'stories/:param';
const STORY_VISIBILITY = 'stories/localized-stories/:param/publish';
const STORY_NODE = 'stories/localized-stories/nodes/:param';
const LOGIN = '/auth/login';
const REFRESH_TOKEN = '/auth/refresh-token';
const FORGOT_PASSWORD = '/auth/forgot-password';
const VALIDATE_CODE = '/auth/validate-recovery-code';
const RESET_PASS = '/auth/reset-password';
const CHANGE_PASS = '/auth/change-password';
const CURRENT_COACH = '/coaches/me';
const MY_APPOINTMENTS = '/coaches/me/appointments';
const MY_AVAILABILITY = '/coaches/me/availability';
const ADD_MY_PROFILE_PICTURE = '/coaches/me/profile-picture';
const ADD_MY_CERTIFICATES = '/coaches/me/certificates';
const DELETE_MY_PROFILE_PICTURE = '/coaches/me/profile-picture';
const DELETE_MY_CERTIFICATION = '/coaches/me/certificates?certificationId=:param';
const FREE_STORY = '/stories/:param/free';
const BUSUBSCRIPTIONS_LIST = '/users/busubscriptions-list';

const urls = {
  'users-list': USERS_LIST,
  'user-info': USER,
  'post-profile-picture': ADD_PROFILE_PICTURE,
  'post-photos': ADD_PHOTOS,
  'delete-user': DELETE_USER,
  'update-user': UPDATE_USER,
  'delete-profile-picture': DELETE_PROFILE_PICTURE,
  'coaches-list': COACHES_LIST,
  'coach-info': COACH,
  'delete-photo': DELETE_PHOTO,
  'update-user-status': UPDATE_USER_STATUS,
  'update-coach-status': UPDATE_COACH_STATUS,
  'post-coach-picture': ADD_COACH_PICTURE,
  'post-certificates': ADD_CERTIFICATES,
  'update-coach': UPDATE_COACH,
  'delete-coach-picture': DELETE_COACH_PROFILE_PICTURE,
  'delete-certification': DELETE_CERTIFICATION,
  'rejection-reasons': REJECTION_REASONS,
  'approve-profile': APPROVE_PROFILE,
  'coach-availability': COACH_AVAILABILITY,
  'delete-availability': DELETE_AVAILABILITY,
  'wellness-sessions': WELLNESS_SESSIONS,
  'stories-info': STORIES,
  'story-media': STORY_MEDIA,
  'localized-story': LOCALIZED_STORY,
  'story-update': STORY,
  'story-visibility': STORY_VISIBILITY,
  'patch-localized-story': PATCH_LOCALIZED_STORY,
  'story-node': STORY_NODE,
  'login-user': LOGIN,
  'forgot-password': FORGOT_PASSWORD,
  'validate-code': VALIDATE_CODE,
  'reset-pass': RESET_PASS,
  'current-coach': CURRENT_COACH,
  'my-appointments': MY_APPOINTMENTS,
  'my-availability': MY_AVAILABILITY,
  'add-my-profile-picture': ADD_MY_PROFILE_PICTURE,
  'add-my-certificates': ADD_MY_CERTIFICATES,
  'delete-my-profile-picture': DELETE_MY_PROFILE_PICTURE,
  'delete-my-certification': DELETE_MY_CERTIFICATION,
  'refresh-token': REFRESH_TOKEN,
  'change-pass': CHANGE_PASS,
  'free-story': FREE_STORY,
  'bussiness-list': BUSSINESS_LIST,
  'busubscriptions-list': BUSUBSCRIPTIONS_LIST,
  'bussiness-user-list': BUSSINESS_USER_LIST,
};

export default urls;
export const BuildUrl = (url: string, param: any, secondParam?: any) => {
  if (secondParam) {
    let paramUrl = '';
    paramUrl = url.replace(':param', param);
    paramUrl = paramUrl.replace(':secondParam', secondParam);
    return paramUrl;
  } else {
    return url.replace(':param', param);
  }
};
