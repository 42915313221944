import urls, { BuildUrl } from 'src/common/constants/urls';

// Services
import { axiosClient } from 'src/common/services/httpService';

// Interfaces
import { UserGeneralInfo } from 'src/interfaces/usersInterface';

// Delete user
export const deleteUserAction = (publicId: string) =>
  axiosClient.delete(urls['delete-user'], {
    params: {
      publicId: publicId,
    },
  });

// Delete user profile picture
export const deleteUserProfilePictureAction = (publicId: string | undefined) =>
  axiosClient.delete(BuildUrl(urls['delete-profile-picture'], publicId));

// Deactivate / activate user
export const updateUserStatusAction = async (props: {
  publicId: string | undefined;
  status: boolean;
}) => {
  const userStatus = await axiosClient.patch(BuildUrl(urls['update-user-status'], props.publicId), {
    isThisUserActivated: props.status,
  });

  return userStatus;
};

// Create user
export const createUserAction = (newUser: UserGeneralInfo) =>
  axiosClient.post(urls['user-info'], newUser);

// Upload user profile picture
export const uploadProfilePictureAction = (props: {
  publicId: string | undefined;
  profilePicture: FormData;
}) =>
  axiosClient.post(BuildUrl(urls['post-profile-picture'], props.publicId), props.profilePicture, {
    headers: { 'Content-Type': `undefined ` },
    validateStatus: function (status) {
      return status < 500;
    },
  });

// Upload user photos
export const uploadProfilePhotosAction = (props: { publicId: string; profilePhotos: FormData }) =>
  axiosClient.post(BuildUrl(urls['post-photos'], props.publicId), props.profilePhotos);

// Update user //Update user
export const updateUserAction = (props: { publicId: string | undefined; updatedUser: any }) =>
  axiosClient.patch(BuildUrl(urls['update-user'], props.publicId), props.updatedUser);

// Get user detail
export const getUserDetailAction = (publicId: string) =>
  axiosClient.get(urls['user-info'], {
    params: {
      publicId: publicId,
    },
  });

// handle approve/reject user profile
export const handleApproveRejectUserProfileAction = (props: {
  publicId?: string;
  data: { isAValidProfile: boolean; reasonsList: any[] };
}) =>
  axiosClient.patch(BuildUrl(urls['approve-profile'], props.publicId), {
    isAValidProfile: props.data.isAValidProfile,
    reasonsList: props.data.reasonsList,
  });

// Get users list
export const getUsersListAction = (users?: { page?: number; limit?: number }) =>
  axiosClient.get(urls['users-list'], {
    params: {
      page: users?.page,
      limit: users?.limit || 10,
    },
  });

// Get Bussiness users list
export const getSpocListAction = (SearchKey?: string) =>
  axiosClient.get(urls['bussiness-list'], {
    params: {
      SearchKey: SearchKey,
    },
  });

export const getBussinessUserListAction = (SearchKey?: string) =>
  axiosClient.get(urls['bussiness-user-list'], {
    params: {
      SearchKey: SearchKey,
    },
  });
// Get buSubscriprionlist users list
export const getBuSubscriptionListListAction = (buSubscription?: {
  page?: number;
  limit?: number;
}) =>
  axiosClient.get(urls['busubscriptions-list'], {
    params: {
      page: buSubscription?.page,
      limit: buSubscription?.limit || 10,
    },
  });

// Delete user photos
export const deleteUserPhoto = (props: {
  publicId: string | undefined;
  photoId: string | number;
}) => axiosClient.delete(BuildUrl(urls['delete-photo'], props.publicId, props.photoId));

// Get reject reasons action
export const getRejectReasonsAction = () => axiosClient.get(urls['rejection-reasons']);
