import { useLottie } from 'lottie-react';
import { useAppSelector } from 'src/hooks';
import loadingBar from './loading-bar.json';
// import { ILoading } from './loading.interface';

function Loading() {
  const isVisible = useAppSelector((state) => state.general.isLoading);
  const options = {
    loop: true,
    animationData: loadingBar,
  };
  const { View } = useLottie(options);

  return (
    <div className={`loading ${isVisible ? 'flex' : 'hidden'}`}>
      <div className='container-load'>
        <h3 className='text-xl mt-6 sm:mt-12 -mb-10'>Loading</h3>
        <div className='animation'>{View}</div>
      </div>
    </div>
  );
}

export default Loading;
