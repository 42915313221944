import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import StoriesImg from '../../../../assets/img/stories.svg';
import { WritableDraft } from 'immer';
import ReactPlayer from 'react-player';

// Components
import Icon from 'src/ui/Icon';

// Redux
import { setStoryIsPublished } from 'src/redux/slices/stories.slice';

// Interfaces
import { EntryNodeI } from 'src/redux/state.types';

const findChild = (
  id: string,
  children?: Array<WritableDraft<EntryNodeI>> | null,
): WritableDraft<EntryNodeI> | null => {
  if (!children) {
    return null;
  }

  let child: WritableDraft<EntryNodeI> | null = children.find((child) => child.id === id) || null;

  if (!child) {
    children.forEach((c) => {
      child = child || findChild(id, c.children);
    });
  }

  return child || null;
};

const ViewStoryDetail = (props: { data: any; initialLang: string }) => {
  const storyIsPublished = useAppSelector((state) => state.stories.storyIsPublished);
  const params = useParams<{ storyId: string }>();
  localStorage.setItem('currentStoryId', JSON.stringify(params.storyId));
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['localizedStory']);
  const [previous, setPrevious] = useState<Array<string>>([]);
  const [currentId, setCurrentId] = useState<string>('');
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const findNode = () => {
    if (props.data?.id) {
      const intro = props.data.entryNode;
      if (currentId) {
        return currentId === intro.id ? intro : findChild(currentId, intro.children);
      } else {
        return props.data;
      }
    }
    return null;
  };

  const current = findNode();

  const fetchChildren = (children?: any) => {
    return children?.filter((node: any) => !node.isDeleted);
  };

  const handleStoryHeader = () => {
    if (current.entryNode) {
      return <p>{t('trailerHeader')}</p>;
    } else {
      if (current.type !== 'Question') {
        return <p>{t('playingHeader')}</p>;
      } else return <p>{t('questionHeader')}</p>;
    }
  };

  useEffect(() => {
    if (props.data.isPublished) {
      dispatch(setStoryIsPublished(true));
    } else dispatch(setStoryIsPublished(false));
  }, [dispatch, props.data.isPublished]);

  useEffect(() => {
    setCurrentId('');
    setPrevious([]);
  }, [props.data.locale]);

  if (!current || props.data.isDeleted) {
    return (
      <div className='flex flex-col items-center justify-center'>
        <img src={StoriesImg} alt='no story' width={150} />
        <div className='mt-8 text-2xl font-medium'>{t('noStory')}</div>
      </div>
    );
  }

  return (
    <>
      <div className='flex justify-center items-center w-full'>
        <div
          className={`flex flex-col ${
            current.type === 'Question' ? 'w-4/6' : 'w-3/5'
          } rounded-2xl p-4 bg-sidebarHover my-8`}
        >
          <div className='nodes w-full relative'>
            <span className='text-2xl mr-4 w-full flex flex-col text-center mb-4'>
              {handleStoryHeader()}
            </span>
            {current.type !== 'Question' && current.medium && (
              <div className='flex justify-center'>
                <ReactPlayer
                  url={current.medium.url}
                  playing={isPlaying}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  controls
                  volume={1}
                  width='100%'
                  height='300px'
                  light={current.medium.thumbnailUrl}
                  onClickPreview={() => setIsPlaying(true)}
                />
              </div>
            )}
            {current.type === 'Question' && <hr className={`w-full border border-white `} />}
            <div className='p-4 rounded-lg mb-4'>
              {current.entryNode && (
                <>
                  <div className='text-xl font-medium'>{current.title}</div>
                  <div className='story-status flex justify-start align-center'>
                    <Icon
                      icon={`${storyIsPublished ? 'published' : 'notPublished'}`}
                      extraClass='w-8 '
                    ></Icon>
                    {`${storyIsPublished ? t('publised') : t('notPublished')}`}
                  </div>
                </>
              )}
              <div className='w-full'>
                <div className='text-2xl text-center font-medium'>
                  {current.question ? <p>{current.question}</p> : ''}
                </div>
                {
                  //current.description && <p>{current.description}</p>
                }
              </div>
            </div>
          </div>
          {current.type === 'Question' && fetchChildren(current.children) && (
            <>
              <div className='flex w-full justify-center'>
                {fetchChildren(current.children).map((node: EntryNodeI) => (
                  <div key={node.id} className='w-1/3 flex justify-between'>
                    <button
                      className='options-card w-full justify-center flex flex-col'
                      onClick={() => {
                        if (
                          node.type === 'Final' ||
                          node.type === 'LearningMoment' ||
                          node.type === 'Question' ||
                          node.type === 'Restart'
                        ) {
                          previous.push(current.id);
                          setPrevious(previous);
                          setCurrentId(node.id);
                        } else {
                          previous.push(current.id);
                          setPrevious(previous);
                          const children = fetchChildren(node.children);
                          if (children) {
                            setCurrentId(children[0].id);
                          }
                        }
                      }}
                    >
                      <p>{`${node.statement}`}</p>
                    </button>
                  </div>
                ))}
              </div>
            </>
          )}
          <hr
            className={`w-full border border-white ${
              current.type === 'Question' ? 'mt-8' : 'mt-0'
            }`}
          />
          <div className='flex mt-8 justify-center'>
            {
              //previous.length > 0 && (
              <>
                <div className='w-4/12 mr-4'>
                  <button
                    className={`back-btn-story ${
                      previous.length === 0 ? 'shadow-none opacity-50' : 'shadow hover:shadow-lg'
                    }`}
                    disabled={previous.length === 0}
                    onClick={() => {
                      const prev = previous.pop();
                      setPrevious(previous);
                      if (current.type === 'Introduction') {
                        setCurrentId('');
                      } else {
                        if (prev) setCurrentId(prev);
                      }
                    }}
                  >
                    {current.entryNode ? t('selectNextBtn') : t('backBtn')}
                  </button>
                </div>
              </>
            }

            {
              // current.type !== 'Question' && current.children && fetchChildren(current.children)?.length > 0 && (
              <div className='w-4/12 mr-4'>
                <button
                  className={`text-sm font-medium rounded-xl focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 p-2 normal-case  text-white bg-secondary border-2 border-white border-solid ${
                    current.type === 'Question' ||
                    current.type === 'Final' ||
                    (current.children &&
                      current.children &&
                      fetchChildren(current.children)?.length === 0)
                      ? 'shadow-none opacity-50'
                      : 'shadow hover:shadow-lg'
                  }`}
                  disabled={
                    current.type === 'Question' ||
                    current.type === 'Final' ||
                    (current.children && fetchChildren(current.children)?.length === 0)
                  }
                  onClick={() => {
                    if (current.type === 'Restart') {
                      const cover = previous.shift();
                      if (cover) setPrevious([cover]);
                      if (cover) setCurrentId(props.data.entryNode.id);
                    } else {
                      previous.push(current.id);
                      setPrevious(previous);
                      const children = fetchChildren(current.children);
                      if (current.entryNode) {
                        setCurrentId(current.entryNode.id);
                      } else if (children) {
                        setCurrentId(children[0].id);
                      }
                    }
                  }}
                >
                  {`${current.type === 'Final' ? t('finalBtn') : t('nextBtn')}`}
                </button>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewStoryDetail;
