import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
// import { useTranslation } from 'react-i18next';

// import UserBussinessForm from 'src/components/forms/admin/users/UserBussinessForm';

// // Redux
// import { setEditUserView } from 'src/redux/slices/users.slice';

// // Components
import UserBussinessForm from 'src/components/forms/admin/users/UserBussinessForm';
// import ConfirmationPopup from 'src/ui/popups/ConfirmationPopup';

// // Interfaces
import { userDetailGeneral, UsersDetailAllInfo } from 'src/interfaces/usersInterface';

// Services
import { getUserDetailUseCase } from 'src/services/users/useCases';

const EditBussinessUser = () => {
  const params = useParams<{ userId: string }>();

  //   const bussinessUserDetail: UsersDetailAllInfo = useAppSelector(
  //     (state) => state.users.bussinessUserDetail,
  //   );

  const userDetail: userDetailGeneral = useAppSelector((state) => state.users.userDetail);
  //   const [showEdit, setShowEdit] = useState<boolean>(false);
  //   const [editInfo, setEditInfo] = useState({});
  //   const editUserView = useAppSelector((state) => state.users.editUserView);
  const dispatch = useAppDispatch();
  //   const { t } = useTranslation(['addEditUserForm']);
  //   const navigate = useNavigate();

  const handleSuccess = async (values: UsersDetailAllInfo) => {
    console.log('values', values);
    //     const editValues: any = {};
    //     if (bussinessUserDetail.fullName !== values.fullName) editValues.fullName = values.fullName;
    //     if (bussinessUserDetail.email !== values.email) editValues.email = values.email;
    //     if (values.phone && bussinessUserDetail.phone !== values.phone) editValues.phone = values.phone;
    //     if (bussinessUserDetail.gender !== values.gender) editValues.gender = values.gender;
    //     if (bussinessUserDetail.businessName !== values.businessName)
    //       editValues.businessName = values.businessName;
    //     if (bussinessUserDetail.spocName !== values.spocName) editValues.spocName = values.spocName;
    //     if (bussinessUserDetail.spocEmail !== values.spocEmail) editValues.spocEmail = values.spocEmail;
    //     if (bussinessUserDetail.numberOfCycles !== values.numberOfCycles)
    //       editValues.numberOfCycles = values.numberOfCycles;
    //     if (bussinessUserDetail.startDate !== values.startDate) editValues.startDate = values.startDate;
    //     if (bussinessUserDetail.endDate !== values.endDate) editValues.endDate = values.endDate;
    //     const updateBussinessUserInfo = Object.assign({}, editValues);
    //     if (Object.keys(updateBussinessUserInfo).length > 0) {
    //       setEditInfo(updateBussinessUserInfo);
    //       setShowEdit(true);
    //     } else {
    //       dispatch(setEditUserView('profile-picture'));
    //     }
  };

  //   const handleEdit = () => {
  //     dispatch(
  //       updateBussinessUserUseCase({
  //         publicId: params.userId,
  //         updatedUser: editInfo,
  //       }),
  //     );
  //     navigate('/admin/users'); // Navigate to the users list after editing
  //   };

  useEffect(() => {
    if (params.userId) {
      dispatch(getUserDetailUseCase(params.userId));
    }
  }, [dispatch, params.userId]);

  return (
    <div>
      <UserBussinessForm data={userDetail} isEditing={true} onSuccess={handleSuccess} />
      {/* <ConfirmationPopup
        isVisible={showEdit}
        title={t('editAlert.title')}
        subtitle={t('editAlert.subtitle')}
        submitBtn={t('editAlert.confirm')}
        handleSubmit={handleEdit}
        onClose={() => setShowEdit(false)}
      /> */}
    </div>
  );
};

export default EditBussinessUser;
