import { createSlice } from '@reduxjs/toolkit';
import { Users } from 'src/interfaces/usersInterface';
import { UsersState } from '../state.types';

const initialState: UsersState = {
  users: {
    isSuccess: false,
    statusCode: '',
    message: '',
    metadata: {
      page: 1,
      perPage: 10,
      pageCount: null,
      totalCount: null,
    },
    data: [],
  },
  bussinessUser: {
    isSuccess: false,
    statusCode: '',
    message: '',
    metadata: {
      page: 1,
      perPage: 10,
      pageCount: null,
      totalCount: null,
    },
    data: [],
  },
  buSubscriptionList: {
    isSuccess: false,
    statusCode: '',
    message: '',
    metadata: {
      page: 1,
      perPage: 10,
      pageCount: null,
      totalCount: null,
    },
    data: [],
  },
  userDetail: {
    fullName: '',
    email: '',
    phone: '',
    gender: '',
    password: '',
    description: '',
    birthDay: '',
    location: {
      stateId: '',
      cityId: '',
    },
    state: '',
    city: '',
    publicId: '',
    preferredLocale: 'en-US',
    status: true,
    consentDate: null,
    userType: '',
    businessName: null,
    spocName: null,
    spocEmail: null,
    buccPopup: true,
    numberOfCycles: 0,
    rateId: {
      value: 0,
      label: '',
      noofCycles: 0,
      noofDays: 0,
      freeRCSessCount: 0,
    },
    startDate: null,
    endDate: null,
    noOfDays: 0,
    couponCycles: 0,
  },
  userProfilePicture: {
    profilePicture: '',
  },
  rejectOptions: [],
  addUserView: '',
  editUserView: '',
  bussinessName: {
    isSuccess: false,
    statusCode: '',
    message: '',
    metadata: null,
    data: [],
  },
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users.data = action.payload;
    },
    setBussinessUser: (state, action) => {
      state.bussinessUser.data = action.payload;
    },
    setBussinessName: (state, action) => {
      state.bussinessName.data = action.payload;
    },

    setBuSubscriptionList: (state, action) => {
      state.buSubscriptionList.data = action.payload;
    },
    deactivateActivateUser: (state, action) => {
      const user = state.users.data.find((u: Users) => u.publicId === action.payload.userId);
      if (user) {
        user.status = action.payload.status;
      }
      return state;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    createUser(state, action) {
      state.users.data.unshift(action.payload);
    },
    updateUser(state, action) {
      state.users.data = state.users.data.map((user: Users) => {
        if (user.publicId === action.payload) {
          user = action.payload;
        }
        return user;
      });
    },
    updateUserProfilePicture(state, action) {
      state.userProfilePicture.profilePicture = action.payload;
    },
    deleteUser: (state, action) => {
      state.users.data = state.users.data.filter((user: Users) => user.publicId !== action.payload);
    },
    deleteUserProfilePicture: (state, action) => {
      const user = state.users.data.find((user: Users) => user.publicId === action.payload);
      user?.profilePicture === '';
    },
    setMetadata: (state, action) => {
      state.users.metadata = action.payload;
    },
    setRejectProfileOptions: (state, action) => {
      state.rejectOptions = action.payload;
    },
    setAddUserView: (state, action) => {
      state.addUserView = action.payload;
    },
    setEditUserView: (state, action) => {
      state.editUserView = action.payload;
    },
  },
});

export const {
  setUsers,
  setBussinessUser,
  setBussinessName,
  setBuSubscriptionList,
  deleteUser,
  createUser,
  updateUser,
  setUserDetail,
  deleteUserProfilePicture,
  deactivateActivateUser,
  setMetadata,
  setRejectProfileOptions,
  setAddUserView,
  setEditUserView,
} = usersSlice.actions;

export default usersSlice.reducer;
